import React from 'react'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { Provider } from 'react-redux'
import App from './App'
import { icons } from './assets/icons'
import './polyfill'
import { withMsal } from "@azure/msal-react";
import { publicClientApplication } from "./util/authConfig";
import { MsalProvider } from "@azure/msal-react";
import store from './util/store'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 12
    }
  }
})

const localStoragePersistor = createLocalStoragePersistor()

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor
})

React.icons = icons

const ProfileWithMsal = withMsal(App);

ReactDOM.render(
  <MsalProvider instance={publicClientApplication}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ProfileWithMsal />
        </Provider>
      </QueryClientProvider>
  </MsalProvider>,
  document.getElementById('root')
)
