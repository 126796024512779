import axios from 'axios'
import produce from 'immer'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  url,
  useInsertPreset,
  useListPreset,
  useRemovePreset,
  useReplacePreset
} from '.'
import { AlertContext } from '../contexts'

// Endpoints
export const getCargoes = ({ lastUpdate, getAll }) =>
  axios.get(`${url}/api/Cargo`, { params: { lastUpdate, getAll } })

export const getCargoUpdates = () =>
  axios.get(`${url}/api/Cargo/GetLatestCargoUpdate`)

export const postCargo = ({ data }) => axios.post(`${url}/api/Cargo`, data)

export const postCopyCargo = ({ data }) => axios.post(`${url}/api/Cargo/Copy/${data}`)

export const putCargo = ({ data }) =>
  axios.put(`${url}/api/Cargo/UpdateCargoes`, [data])

export const deleteCargo = ({ id }) => axios.delete(`${url}​/api/Cargo/${id}`)

export const getFixtures = ({ lastUpdate, getAll }) =>
  axios.get(`${url}/api/Cargo/Fixtures`, { params: { lastUpdate, getAll } })

export const getFixtureUpdates = () =>
  axios.get(`${url}/api/Cargo/GetLatestFixtureUpdate`)

export const sendCargo = ({ onlySave, data }) =>
  axios.post(`${url}/api/Cargo/StatusChange/${onlySave}`, data)

export const getCargoConfirmations = ({ cargoId, chartererId }) =>
  axios.get(
    `${url}/api/CargoConfirmation/Confirmation/GetAll/${cargoId}/${chartererId}`
  )

export const putCargoConfirmation = ({ cargoId, data }) =>
  axios.put(`${url}/api/CargoConfirmation/Confirmation`, data, {
    params: { cargoId }
  })

export const getCargoComments = ({ charterer, chartererId }) =>
  axios.get(`${url}/api/CargoConfirmation/Comment/GetAll/${charterer}?chartererId=${chartererId}`)

export const putCargoComment = ({ data }) =>
  axios.put(`${url}/api/CargoConfirmation/Comment`, data)

export const deleteCargoComment = ({ id }) =>
  axios.delete(`${url}/api/CargoConfirmation/Comment`, {
    params: { chartererCommentId: id }
  })

export const getLatestFixtures = ({ charterer, chartererId, take }) =>
  axios.get(`${url}/api/Cargo/Fixtures/${take}/${charterer}?chartererId=${chartererId}`)

// Queries
export const useCargoesQuery = ({ lastUpdate = null, getAll = false }) => {
  const queryKey = ['cargoes', getAll]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getCargoes({ lastUpdate, getAll }), listPreset),
    queryKey
  }
}

export const useCreateCargoMutation = queryKey => {
  const insertPreset = useInsertPreset(queryKey)
  return useMutation(row => postCargo({ data: row }), insertPreset)
}

export const useEditCargoMutation = queryKey => {
  const replacePreset = useReplacePreset(queryKey)
  return useMutation(row => putCargo({ data: row }), replacePreset)
}

export const useRemoveCargoMutation = queryKey => {
  const removePreset = useRemovePreset(queryKey)
  return useMutation(row => deleteCargo({ id: row.id }), removePreset)
}

export const useFixturesQuery = ({ lastUpdate = null, getAll = false }) => {
  const queryKey = ['fixtures', getAll]
  const listPreset = useListPreset()
  return {
    ...useQuery(
      queryKey,
      () => getFixtures({ lastUpdate, getAll }),
      listPreset
    ),
    queryKey
  }
}

export const useEditFixtureMutation = queryKey => {
  const replacePreset = useReplacePreset(queryKey)
  return useMutation(row => putCargo({ data: row }), replacePreset)
}

export const useRemoveFixtureMutation = queryKey => {
  const removePreset = useRemovePreset(queryKey)
  return useMutation(row => deleteCargo({ id: row.id }), removePreset)
}

export const useSendCargoMutation = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(
    ({ row, onlySave = false }) => sendCargo({ data: row, onlySave }),
    {
      onSuccess: data => {
        const newRow = data.data
        queryClient.setQueryData(
          queryKey,
          produce((currentData = {}) => {
            currentData.data = currentData.data.map(oldRow =>
              oldRow.id === newRow.id ? newRow : oldRow
            )
          })
        )
        pushAlert({
          color: 'success',
          message: 'Your cargo has been saved successfully.'
        })
      },
      onError: (err, row, context) => {
        pushAlert({
          color: 'danger',
          message: 'An error occurred while saving your cargo.'
        })
      }
    }
  )
}

export const useCargoConfirmationsQuery = ({
  row: { id: cargoId, chartererId }
}) => {
  const queryKey = ['cargoConfirmations', cargoId, chartererId]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getCargoConfirmations({ cargoId, chartererId }), {
      ...listPreset,
      enabled: !!cargoId
    }),
    queryKey
  }
}

export const useCargoCommentsQuery = ({ charterer, chartererId }) => {
  const queryKey = ['cargoComments', charterer]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getCargoComments({ charterer, chartererId }), listPreset),
    queryKey
  }
}

export const useLatestFixturesQuery = ({ charterer, chartererId }) => {
  const queryKey = ['latestFixtures', charterer]
  const listPreset = useListPreset()
  return useQuery(
    queryKey,
    () => getLatestFixtures({ charterer, chartererId, take: 5 }),
    listPreset
  )
}

export const useConfirmCargoMutation = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(
    ({ cargo, confirmations }) =>
      putCargoConfirmation({ cargoId: cargo.id, data: confirmations }),
    {
      onSuccess: (data, { cargo }) => {
        queryClient.setQueryData(
          queryKey,
          produce((currentData = {}) => {
            currentData.data = currentData.data.map(row =>
              row.id === cargo.id ? { ...row, chartererConfirmed: true } : row
            )
          })
        )
        pushAlert({
          color: 'success',
          message: 'Your charterer has been confirmed successfully.'
        })
      },
      onError: (err, row, context) => {
        pushAlert({
          color: 'danger',
          message: 'An error occurred while confirming your charteter.'
        })
      }
    }
  )
}

export const usePushCargoCommentMutation = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(row => putCargoComment({ data: row }), {
    onSuccess: ({ data }, input) => {
      queryClient.setQueryData(
        queryKey,
        produce((currentData = {}) => {
          if (input.id) {
            currentData.data = currentData.data.map(row =>
              row.id === data.id ? data : row
            )
          } else {
            currentData.data.push(data)
          }
        })
      )
    },
    onError: (err, row, context) => {
      pushAlert({
        color: 'danger',
        message: 'An error occurred while creating your comment.'
      })
    }
  })
}

export const useRemoveCargoCommentMutation = queryKey => {
  const removePreset = useRemovePreset(queryKey, { silent: true })
  return useMutation(row => deleteCargoComment({ id: row.id }), removePreset)
}
