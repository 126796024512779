import axios from 'axios'
import produce from 'immer'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { url, useListPreset } from '.'
import { AlertContext } from '../contexts'

// Endpoints
export const getEuEts = ({ date, type }) =>
  axios.get(`${url}/api/EuEts/Get/${type}`, {
    params: { date }
  })

const postEuEts = ({ data }) => axios.post(`${url}/api/EuEts/AddUpdateRange`, data)

// Queries
export const useEuEtsQuery = ({ date, type }) => {
  const queryKey = ['euEts', type, date]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getEuEts({ date, type }), listPreset),
    queryKey
  }
}

export const useSetEuEtsMutation = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(
    rows => {
      return postEuEts({ data: rows })
    },
    {
      onMutate: async rows => {
        await queryClient.cancelQueries(queryKey)
        const previousRows = queryClient.getQueryData(queryKey)
        queryClient.setQueryData(
          queryKey,
          produce((currentData = {}) => {
            for (const row of Object.values(rows)) {
              if (row.id) {
                currentData.data = currentData.data.map(currentRow =>
                  currentRow.id === row.id ? row : currentRow
                )
              } else {
                currentData.data.push(row)
              }
            }
          })
        )
        return { previousRows }
      },
      onError: (err, rows, context) => {
        queryClient.setQueryData(queryKey, context.previousRows)
        pushAlert({
          color: 'danger',
          message: 'An error occurred while saving your EU ETS prices.'
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
      onSuccess: () => {
        pushAlert({
          color: 'success',
          message: 'Your EU ETS have been saved successfully.'
        })
        queryClient.invalidateQueries('routeAssessments')
      }
    }
  )
}
