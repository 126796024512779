import axios from 'axios'
import { useMutation, useQueries, useQuery } from 'react-query'
import { url, useEditPreset, useListPreset } from '.'

// Endpoints
const getFilterButtons = ({ siteId }) =>
  axios.get(`${url}/api/FilterButton/${siteId}`)

const getCategoryValues = ({ category }) =>
  axios.get(`${url}/api/CategoryValue`, { params: { category } })

const getAreas = () => axios.get(`${url}/api/Area/GetAll`)

const getPorts = () => axios.get(`${url}/api/Port/GetAll`)

const getSegments = () => axios.get(`${url}/api/Segment/GetAll`)

const putSegment = ({ data }) => axios.put(`${url}/api/Segment`, data)

// Queries
export const useFilterButtonsQuery = ({ siteId }) => {
  const listPreset = useListPreset()
  return useQuery(
    ['filterButtons', siteId],
    () => getFilterButtons({ siteId }),
    listPreset
  )
}

export const useCategoryValuesQuery = ({ category }) => {
  const listPreset = useListPreset()
  return useQuery(
    ['categoryValue', category],
    () => getCategoryValues({ category }),
    listPreset
  )
}

export const useChartererValuesQueries = () => {
  const listPreset = useListPreset()
  return useQuery({
    queryKey: ['chartererValue', 'charterer'],
    queryFn: () => axios.get(`${url}/api/Charterer`),
    ...listPreset,
    staleTime: 1000 * 60 * 60 * 12
  })
}

export const useCategoryValuesQueries = ({role, categories }) => {
  const listPreset = useListPreset()
  
  return useQueries(
    categories.filter(category => category !== 'charterer').map(category => {
      return {
        queryKey: ['categoryValue', role, category],
        queryFn: () => getCategoryValues({ category }),
        ...listPreset,
        staleTime: 1000 * 60 * 60 * 12
      }
    })
  )
}

export const useAreasQuery = () => {
  const listPreset = useListPreset()
  return useQuery('areas', getAreas, listPreset)
}

export const usePortsQuery = () => {
  const listPreset = useListPreset()
  return useQuery('ports', getPorts, listPreset)
}

export const useSegmentsQuery = () => {
  const listPreset = useListPreset()
  return useQuery('segments', getSegments, listPreset)
}

export const useEditSegmentMutation = () => {
  const editPreset = useEditPreset('segments')
  return useMutation(row => putSegment({ data: row }), editPreset)
}
