import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {isAfter, max, parseISO} from 'date-fns'
import * as R from 'ramda'
import {
  deletePosition,
  getPositions,
  getPositionUpdates,
  postPosition,
  putPosition
} from '../queries'
import { alertsSlice } from './alerts'
import {getLastUpdate} from "../datatime";

export const positionsSelector = state => state.positionsSlice

export const fetchPositions = createAsyncThunk(
  'positions/fetchPositions',
  async () => {
    const { data } = await getPositions({ lastUpdate: null })
    return data
  }
)

export const fetchPositionUpdates = createAsyncThunk(
  'positions/fetchPositionUpdates',
  async (_, { getState }) => {
    const { lastUpdate } = positionsSelector(getState())
    if (!lastUpdate) return null

    const { data } = await getPositionUpdates()

    const {positionUpdated, vesselUpdated} = data;
    const remoteLastUpdate = max([parseISO(positionUpdated), parseISO(vesselUpdated)])
    if (isAfter(remoteLastUpdate, parseISO(lastUpdate))) {
      const { data } = await getPositions({ lastPositionUpdate: remoteLastUpdate, lastVesselUpdate: remoteLastUpdate })
      return data
    }
  }
)

export const createPosition = createAsyncThunk(
  'positions/createPosition',
  async input => {
    const { data } = await postPosition({ data: input })
    return data
  }
)

export const editPosition = createAsyncThunk(
  'positions/editPosition',
  async (input, { dispatch }) => {
    dispatch(positionsSlice.actions.set(input))
    const { data } = await putPosition({ data: input })
    return data
  }
)

export const removePosition = createAsyncThunk(
  'positions/removePosition',
  async (input, { dispatch }) => {
    dispatch(positionsSlice.actions.delete(input))
    const { data } = await deletePosition({ id: input.positionId })
    dispatch(
      alertsSlice.actions.push({
        color: 'success',
        message: 'Your position has been deleted successfully.'
      })
    )
    return data
  }
)

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null,
  isCreating: false
}

export const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.positionId] = action.payload
    },
    delete: (state, action) => {
      delete state.data[action.payload.positionId]
    }
  },
  extraReducers: {
    [fetchPositions.pending]: state => {
      state.status = 'loading'
    },
    [fetchPositions.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchPositions.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('positionId'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchPositionUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('positionId'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    },
    [createPosition.pending]: state => {
      state.isCreating = true
    },
    [createPosition.fulfilled]: (state, action) => {
      state.data[action.payload.positionId] = {
        ...action.payload,
        _newity: new Date().getTime()
      }
      state.isCreating = false
      state.lastUpdate = getLastUpdate()
    }
  }
})
