import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isAfter, parseISO } from 'date-fns'
import * as R from 'ramda'
import { getLastUpdate } from '../datatime'
import {
  deleteCargo,
  deleteCargoComment,
  getCargoUpdates,
  getCargoes,
  postCargo,
  postCopyCargo,
  putCargo,
  putCargoComment,
  putCargoConfirmation,
  sendCargo
} from '../queries'
import { alertsSlice } from './alerts'

export const cargoesSelector = state => state.cargoesSlice

export const fetchCargoes = createAsyncThunk(
  'cargoes/fetchCargoes',
  async ({ getAll }) => {
    const { data } = await getCargoes({ lastUpdate: null, getAll })
    return data
  }
)

export const fetchCargoUpdates = createAsyncThunk(
  'cargoes/fetchCargoUpdates',
  async (_, { getState }) => {
    let { lastUpdate } = cargoesSelector(getState())
    if (!lastUpdate) return null

    let { data: remoteLastUpdate } = await getCargoUpdates()
    if (isAfter(parseISO(remoteLastUpdate), parseISO(lastUpdate))) {
      const { data } = await getCargoes({
        lastUpdate: remoteLastUpdate,
        getAll: false
      })
      return data
    }
  }
)

export const createCargo = createAsyncThunk(
  'cargoes/createCargo',
  async input => {
    const { data } = await postCargo({ data: input })
    return data
  }
)

export const editCargo = createAsyncThunk(
  'cargoes/editCargo',
  async (input, { dispatch }) => {
    dispatch(cargoesSlice.actions.set(input))
    const { data } = await putCargo({ data: input })
    dispatch(cargoesSlice.actions.set(data[0]))
    return data
  }
)

export const copyCargo = createAsyncThunk(
  'cargoes/copyCargo',
  async (input, { dispatch }) => {
    const { data } = await postCopyCargo({ data: input })
    return data
  }
)

export const removeCargo = createAsyncThunk(
  'cargoes/removeCargo',
  async (input, { dispatch }) => {
    dispatch(cargoesSlice.actions.delete(input))
    const { data } = await deleteCargo({ id: input.id })
    dispatch(
      alertsSlice.actions.push({
        color: 'success',
        message: 'Your cargo has been deleted successfully.'
      })
    )
    return data
  }
)

export const sendCargoStatus = createAsyncThunk(
  'cargoes/sendCargoStatus',
  async ({ row, onlySave = false }) => {
    const { data } = await sendCargo({ data: row, onlySave })
    return data
  }
)

export const confirmCargo = createAsyncThunk(
  'cargoes/confirmCargo',
  async ({ cargo, confirmations, comments, _delete }, { dispatch }) => {
    try {
      await Promise.all([
        ...Object.values(comments).map(data => putCargoComment({ data })),
        ...(_delete?.comments || []).map(data =>
          deleteCargoComment({ id: data.id })
        )
      ])
      const { data } = await putCargoConfirmation({
        cargoId: cargo.id,
        data: confirmations
      })
      return data
    } catch (err) {
      dispatch(
        alertsSlice.actions.push({
          color: 'danger',
          message: 'An error occurred while confirming this cargo.'
        })
      )
    }
  }
)

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null,
  isCreating: false
}

export const cargoesSlice = createSlice({
  name: 'cargoes',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.id] = action.payload
    },
    delete: (state, action) => {
      delete state.data[action.payload.id]
    }
  },
  extraReducers: {
    [fetchCargoes.pending]: state => {
      state.status = 'loading'
    },
    [fetchCargoes.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchCargoes.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('id'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchCargoUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('id'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    },
    [createCargo.pending]: state => {
      state.isCreating = true
    },
    [createCargo.fulfilled]: (state, action) => {
      state.data[action.payload.id] = {
        ...action.payload,
        _newity: new Date().getTime()
      }
      state.isCreating = false
      state.lastUpdate = getLastUpdate()
    },
    [copyCargo.pending]: state => {
      state.isCreating = true
    },
    [copyCargo.fulfilled]: (state, action) => {
      state.data[action.payload.id] = {
        ...action.payload,
        _newity: new Date().getTime()
      }
      state.isCreating = false
      state.lastUpdate = getLastUpdate()
    },
    [sendCargoStatus.fulfilled]: (state, action) => {
      state.data[action.payload.id] = action.payload
      state.lastUpdate = getLastUpdate()
    },
    [confirmCargo.fulfilled]: (state, action) => {
      const allConfirmed =
        action.payload.length &&
        action.payload.every(({ confirmed }) => confirmed)
      const oldData = state.data[action.payload[0].cargoId]

      if (oldData.chartererConfirmed !== allConfirmed) {
        state.data[action.payload[0].cargoId] = {
          ...oldData,
          chartererConfirmed: allConfirmed
        }
        state.lastUpdate = getLastUpdate()
      }
    }
  }
})
