import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  alertsSlice,
  cargoesSlice,
  fixturesSlice,
  fleetSlice,
  positionsSlice,
  timeChartersSlice,
  vesselTradeSlice
} from './slices'

const rootReducer = combineReducers({
  sidebarShow: (state = 'responsive', { type, sidebarShow }) => {
    switch (type) {
      case 'set':
        return sidebarShow
      default:
        return state
    }
  },
  alertsSlice: alertsSlice.reducer,
  fleetSlice: fleetSlice.reducer,
  positionsSlice: positionsSlice.reducer,
  cargoesSlice: cargoesSlice.reducer,
  fixturesSlice: fixturesSlice.reducer,
  timeChartersSlice: timeChartersSlice.reducer,
  vesselTradeSlice: vesselTradeSlice.reducer
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production'
})

export default store
