import axios from 'axios'
import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { url, useCreatePreset, useListPreset, useRemovePreset } from '.'
import {MsalContext} from "@azure/msal-react";

// Endpoints
export const getUserCategoryValues = ({siteId}) =>
  axios.get(`${url}/api/UserCategoryValue?siteId=${siteId}`)

export const getUserCategoryAllValues = () =>
  axios.get(`${url}/api/UserCategoryValue/GetAllValues`)

const putUserCategoryValue = ({ data }) =>
  axios.put(`${url}/api/UserCategoryValue/ChangeUserCategoryValue`, data)

const deleteUserCategoryValue = ({ id }) =>
  axios.get(`${url}/api/UserCategoryValue/DeleteCategoryValue/${id}`)

export const getUserColumnSort = ({ userId, siteId }) =>
  axios.get(`${url}/api/UserColumnSort/GetUserColumnSort`, {
    params: { userId, siteId }
  })

const putUserColumnSort = ({ data }) =>
  axios.put(`${url}/api/UserColumnSort/ChangeSort`, data)

// Queries
export const useUserCategoryValuesQuery = ({ siteId }) => {
  const listPreset = useListPreset()
  return useQuery('userCategoryValues', row => getUserCategoryValues({ siteId }), {
    ...listPreset,
    staleTime: 1000 * 60 * 5
  })
}
export const useUserCategoryAllValuesQuery = () => {
  const listPreset = useListPreset()
  return useQuery('userCategoryAllValues', getUserCategoryAllValues, {
    ...listPreset,
    staleTime: 1000 * 60 * 5
  })
}

export const usePushUserCategoryValueMutation = ({ silent } = {}) => {
  const createPreset = useCreatePreset('userCategoryValues', { silent })
  return useMutation(row => putUserCategoryValue({ data: row }), createPreset)
}

export const useRemoveUserCategoryValueMutation = ({ silent } = {}) => {
  const removePreset = useRemovePreset('userCategoryValues', { silent })
  return useMutation(
    row => deleteUserCategoryValue({ id: row.id }),
    removePreset
  )
}

export const usePushUserColumnSortMutation = () => {
  const { accounts } = useContext(MsalContext)
  const { username } = accounts[0] || {}
  const userId = username?.toLowerCase()
  return useMutation(row =>
    putUserColumnSort({
      data: {
        userId,
        ...row
      }
    })
  )
}
