import axios from 'axios'
import AxiosStream from 'axios-stream'
import produce from 'immer'
import { isNil } from 'ramda'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getFormattedDate } from '../../shared/ui/magic-table/dateHelpers'
import { loginRequest, publicClientApplication } from '../authConfig'
import { AlertContext } from '../contexts'

export const url = process.env.REACT_APP_API_URL

// Options
axios.interceptors.request.use(async config => {
  if (!isNil(config.headers.Authorization)) {
    return config
  }

  const accounts = publicClientApplication.getAllAccounts()
  if (accounts.length === 0) {
    window.location.reload()
  }
  const request = {
    scopes: loginRequest.scopes,
    account: accounts[0]
  }
  return publicClientApplication
    .acquireTokenSilent(request)
    .then(authResult => {
      config.headers.Authorization = `Bearer ${authResult.accessToken}`
      return config
    })
    .catch(error => {
      if (error.name === 'InteractionRequiredAuthError') {
        window.location.replace(`${window.location.origin}/login`)
      }
    })
})

// Endpoints
export * from './bunkerPrice'
export * from './cargoes'
export * from './euEts'
export * from './misc'
export * from './route'
export * from './routeAssessments'
export * from './user'
export * from './vesselTrade'

export const getVessels = ({ lastUpdate }) => 
  axios.get(`${url}/api/Vessel`, { params: { lastUpdate } })

  export const getVesselsLookup = ({ lastUpdate }) =>
  axios.get(`${url}/api/Position/GetAllVessels`, { params: { lastUpdate } })


export const getVesselUpdates = () =>
  axios.get(`${url}/api/Vessel/GetLatestUpdate`)

export const putVessel = ({ id, data }) =>
  axios.put(`${url}/api/Vessel/${id}`, data)

export const updateVessels = ({ data }) => axios.put(`${url}/api/Vessel/`, data)

export const getPositions = ({ lastUpdate }) =>
  axios.get(`${url}/api/Position`, { params: { lastUpdate } })

export const getPositionUpdates = () =>
  axios.get(`${url}/api/Position/GetLatestUpdate`)

export const postPosition = ({ data }) =>
  axios.post(`${url}/api/Position`, data)

export const putPosition = ({ data }) =>
  axios.put(`${url}/api/Position/Update`, [data])

export const deletePosition = ({ id }) =>
  axios.delete(`${url}​/api/Position/${id}`)

export const getTimeCharters = () => axios.get(`${url}/api/TimeCharter`)

export const getTimeCharterUpdates = () =>
  axios.get(`${url}/api/TimeCharter/GetLatestUpdate`)

export const postTimeCharter = ({ data }) =>
  axios.post(`${url}/api/TimeCharter`, data)

export const putTimeCharter = ({ data }) =>
  axios.put(`${url}/api/TimeCharter`, [data])

export const deleteTimeCharter = ({ id }) =>
  axios.delete(`${url}/api/TimeCharter/${id}`)

const getBlobFiles = () => axios.get(`${url}/api/BlobStorage/ListFiles`)

const getBlobLogs = () => axios.get(`${url}/api/BlobStorage/Log`)

const getBlobExceptions = () => axios.get(`${url}/api/BlobStorage/Exceptions`)

const deleteBlobFile = ({ fileName }) =>
  axios.get(`${url}/api/BlobStorage/DeleteFile/${fileName}`)

const getRecipients = () => axios.get(`${url}/api/Recipient`)

const postRecipient = ({ data }) => axios.post(`${url}/api/Recipient`, data)

const deleteRecipient = ({ id }) =>
  axios.delete(`${url}/api/Recipient/delete/${id}`)

// Exporters
export const downloadCargoes = ({ onlyFixtures }) =>
  AxiosStream.download(`Cargoes_${getFormattedDate()}`, 'xlsx', {
    url: `${url}/api/Cargo/ExportToExcel`,
    params: { onlyFixtures }
  })

export const downloadVessels = () =>
  AxiosStream.download(`Vessel_${getFormattedDate()}`, 'xlsx', {
    url: `${url}/api/Vessel/ExportToExcel`
  })

export const downloadTimeCharters = () =>
  AxiosStream.download(`TimeCharter_${getFormattedDate()}`, 'xlsx', {
    url: `${url}/api/TimeCharter/ExportToExcel`
  })

export const downloadPositions = ({
  location,
  segment,
  orderColumn,
  orderDirection
}) =>
  AxiosStream.download(`Positions_${getFormattedDate()}`, 'pdf', {
    url: `${url}/api/pdf`,
    params: {
      location: JSON.stringify(location),
      segment: JSON.stringify(segment),
      orderColumn: orderColumn,
      orderDirection: orderDirection
    }
  })

export const downloadBlobFile = ({ fileName }) =>
  AxiosStream.download(fileName.split('.')[0], 'csv', {
    url: `${url}/api/BlobStorage/DownloadFile/${fileName}`
  })

// Uploaders
const uploadBlobFile = ({ file }) => {
  const formData = new FormData()
  formData.append('asset', file)
  return axios.post(`${url}/api/BlobStorage/InsertFile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// Presets
export const useListPreset = () => {
  const { pushAlert } = useContext(AlertContext)

  return {
    select: data => data.data,
    retry: false,
    onError: () => {
      pushAlert({
        color: 'danger',
        message:
          'Something wrong happened while fetching data. Please check your connection. If the error persists, contact your administrator.'
      })
    }
  }
}

export const useReadPreset = () => {
  const { pushAlert } = useContext(AlertContext)

  return {
    select: data => data.data,
    retry: false,
    onError: () => {
      pushAlert({
        color: 'danger',
        message:
          'Something wrong happened while fetching data. Please check your connection. If the error persists, contact your administrator.'
      })
    }
  }
}

const useMutationPresetGenerator = (queryKey, optimisticFn, silent) => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return {
    onMutate: async row => {
      // await queryClient.cancelQueries(queryKey)
      const previousRows = queryClient.getQueryData(queryKey)
      queryClient.setQueryData(
        queryKey,
        produce((currentData = {}) => {
          currentData.data = optimisticFn(currentData.data || [], row)
        })
      )
      return { previousRows }
    },
    onError: (err, row, context) => {
      queryClient.setQueryData(queryKey, context.previousRows)
      pushAlert({
        color: 'danger',
        message: 'An error occurred while saving your changes.'
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onSuccess: () => {
      if (!silent) {
        pushAlert({
          color: 'success',
          message: 'Your changes have been saved successfully.'
        })
      }
    }
  }
}

export const useCreatePreset = (queryKey, { silent } = {}) =>
  useMutationPresetGenerator(queryKey, (rows, row) => [...rows, row], silent)

export const useEditPreset = (
  queryKey,
  { idProp = 'id', silent = true } = {}
) =>
  useMutationPresetGenerator(
    queryKey,
    (rows, row) =>
      rows.map(oldRow => (oldRow[idProp] === row[idProp] ? row : oldRow)),
    silent
  )

export const useRemovePreset = (queryKey, { idProp = 'id', silent } = {}) =>
  useMutationPresetGenerator(
    queryKey,
    (rows, row) =>
      rows.filter(currentRow => currentRow[idProp] !== row[idProp]),
    silent
  )

export const useInsertPreset = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return {
    onError: (err, row, context) => {
      queryClient.setQueryData(queryKey, context.previousRows)
      pushAlert({
        color: 'danger',
        message: 'An error occurred while creating this item.'
      })
    },
    onSuccess: data => {
      queryClient.setQueryData(
        queryKey,
        produce((currentData = {}) => {
          currentData.data.push({
            ...data.data,
            _newity: new Date().getTime()
          })
        })
      )
    }
  }
}

export const useReplacePreset = (queryKey, { idProp = 'id' } = {}) => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return {
    onMutate: row => {
      const previousRows = queryClient.getQueryData(queryKey)
      queryClient.setQueryData(
        queryKey,
        produce((currentData = {}) => {
          currentData.data = currentData.data.map(oldRow =>
            oldRow[idProp] === row[idProp] ? row : oldRow
          )
        })
      )
      return { previousRows }
    },
    onSuccess: data => {
      const [newRow] = data.data
      queryClient.setQueryData(
        queryKey,
        produce((currentData = {}) => {
          currentData.data = currentData.data.map(oldRow =>
            oldRow[idProp] === newRow[idProp]
              ? {
                  ...oldRow,
                  ...newRow
                }
              : oldRow
          )
        })
      )
    },
    onError: (err, row, context) => {
      queryClient.setQueryData(queryKey, context.previousRows)
      pushAlert({
        color: 'danger',
        message: 'An error occurred while saving your changes.'
      })
    }
  }
}

//
// Queries
//

// Vessels
export const useVesselsQuery = ({ lastUpdate = null }) => {
  const listPreset = useListPreset()
  return useQuery('vessels', () => getVessels({ lastUpdate }), listPreset)
}

// Vessels Lookup
export const useVesselsLookupQuery = ({ lastUpdate = null }) => {
  const listPreset = useListPreset()
  return useQuery('vesselsLookup', () => getVesselsLookup({ lastUpdate }), listPreset)
}

export const useEditVesselMutation = () => {
  const editPreset = useEditPreset('vessels')
  return useMutation(row => putVessel({ id: row.id, data: row }), editPreset)
}

// Positions
export const usePositionsQuery = ({ lastUpdate = null }) => {
  const queryKey = ['positions', lastUpdate]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getPositions({ lastUpdate }), listPreset),
    queryKey
  }
}

export const useCreatePositionMutation = queryKey => {
  const insertPreset = useInsertPreset(queryKey)
  return useMutation(row => postPosition({ data: row }), insertPreset)
}

export const useEditPositionMutation = queryKey => {
  const replacePreset = useReplacePreset(queryKey, { idProp: 'positionId' })
  return useMutation(row => putPosition({ data: row }), replacePreset)
}

export const useRemovePositionMutation = queryKey => {
  const removePreset = useRemovePreset(queryKey, { idProp: 'positionId' })
  return useMutation(
    row => deletePosition({ id: row.positionId }),
    removePreset
  )
}

// Time Charters
export const useTimeChartersQuery = () => {
  const listPreset = useListPreset()
  return useQuery('timeCharters', getTimeCharters, listPreset)
}

export const useCreateTimeCharterMutation = () => {
  const insertPreset = useInsertPreset('timeCharters')
  return useMutation(row => postTimeCharter({ data: row }), insertPreset)
}

export const useEditTimeCharterMutation = () => {
  const replacePreset = useReplacePreset('timeCharters')
  return useMutation(row => putTimeCharter({ data: row }), replacePreset)
}

export const useRemoveTimeCharterMutation = () => {
  const removePreset = useRemovePreset('timeCharters')
  return useMutation(row => deleteTimeCharter({ id: row.id }), removePreset)
}

// Blob Files (Import)
export const useBlobFilesQuery = () => {
  const listPreset = useListPreset()
  return useQuery('blobFiles', () => getBlobFiles(), listPreset)
}

export const useBlobLogsQuery = () => {
  const listPreset = useListPreset()
  return useQuery('blobLogs', () => getBlobLogs(), listPreset)
}

export const useBlobExceptionsQuery = () => {
  const listPreset = useListPreset()
  return useQuery('blobExceptions', () => getBlobExceptions(), listPreset)
}

export const useUploadBlobFileMutation = () => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(file => uploadBlobFile({ file }), {
    onSuccess: (_, file) => {
      queryClient.invalidateQueries('blobFiles')
      pushAlert({
        color: 'success',
        message: `Your file "${file.name}" has been uploaded successfully.`
      })
    },
    onError: (_, file) => {
      pushAlert({
        color: 'danger',
        message: `Something wrong happened while trying to import "${file.name}". Please double check the file format and/or content and try again.`
      })
    }
  })
}

export const useRemoveBlobFileMutation = () => {
  const removePreset = useRemovePreset('blobFiles', { idProp: 'fileName' })
  return useMutation(
    row => deleteBlobFile({ fileName: row.fileName }),
    removePreset
  )
}

// Recipients (Mailing Groups)
export const useRecipientsQuery = () => {
  const listPreset = useListPreset()
  return useQuery('recipients', () => getRecipients(), listPreset)
}

export const useCreateRecipientMutation = () => {
  const createPreset = useCreatePreset('recipients')
  return useMutation(row => postRecipient({ data: row }), createPreset)
}

export const useRemoveRecipientMutation = () => {
  const removePreset = useRemovePreset('recipients')
  return useMutation(row => deleteRecipient({ id: row.id }), removePreset)
}
