import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'

//Empty user query cache, we will be able to use this if we need to clear again by comparing the date
if (!localStorage.getItem("REACT_QUERY_OFFLINE_CACHE_CLEARED")) {
  localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
  localStorage.setItem("REACT_QUERY_OFFLINE_CACHE_CLEARED", new Date().toUTCString());
}

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path='/login'
              name='Login Page'
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path='/logout'
              name='Logout Page'
              render={props => <Logout {...props} />}
            />
            <Route
              exact
              path='/register'
              name='Register Page'
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={props => <Page500 {...props} />}
            />
            <Route
              path='/'
              name='Home'
              render={props => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
