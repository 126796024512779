import axios from 'axios'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  url,
  useCreatePreset,
  useEditPreset,
  useListPreset,
  useRemovePreset
} from '.'
import { AlertContext } from '../contexts'
import { isNil } from 'ramda'

// Endpoints
const getRoutes = ({type}) => axios.get(`${url}/api/Route/GetAll/${type}`)

const getRoute = ({ id }) => axios.get(`${url}/api/Route/${id}`)

const postRoute = ({ data }) => axios.post(`${url}/api/Route`, data)

const putRoute = ({ data }) => axios.put(`${url}/api/Route`, data)

const deleteRoute = ({ id }) => axios.delete(`${url}/api/Route/${id}`)

const getPortConsumptions = ({ routeId }) =>
  axios.get(`${url}/api/Consumption/PortConsumption/GetAll/${routeId}`)

const postPortConsumption = ({ data }) =>
  axios.post(`${url}/api/Consumption/PortConsumption`, data)

const putPortConsumption = ({ data }) =>
  axios.put(`${url}/api/Consumption/PortConsumption`, data)

const deletePortConsumption = ({ id }) =>
  axios.delete(`${url}/api/Consumption/PortConsumption/${id}`)

const getSeaConsumptions = ({ routeId }) =>
  axios.get(`${url}/api/Consumption/SeaConsumption/GetAll/${routeId}`)

const postSeaConsumption = ({ data }) =>
  axios.post(`${url}/api/Consumption/SeaConsumption`, data)

const putSeaConsumption = ({ data }) =>
  axios.put(`${url}/api/Consumption/SeaConsumption`, data)

const deleteSeaConsumption = ({ id }) =>
  axios.delete(`${url}/api/Consumption/SeaConsumption/${id}`)

const getFuelConsumptions = ({ routeId }) =>
  axios.get(`${url}/api/Consumption/FuelConsumption/GetAll/${routeId}`)

const postFuelConsumption = ({ data }) =>
  axios.post(`${url}/api/Consumption/FuelConsumption`, data)

const putFuelConsumption = ({ data }) =>
  axios.put(`${url}/api/Consumption/FuelConsumption`, data)

const deleteFuelConsumption = ({ id }) =>
  axios.delete(`${url}/api/Consumption/FuelConsumption/${id}`)

const getCombinedRoutes = ({ routeId }) =>
  axios.get(`${url}/api/Route/Combined/GetAll/${routeId}`)

const postCombinedRoute = ({ data }) =>
  axios.post(`${url}/api/Route/Combined`, data)

const putCombinedRoute = ({ data }) =>
  axios.put(`${url}/api/Route/Combined`, data)

const deleteCombinedRoute = ({ id }) =>
  axios.delete(`${url}/api/Route/Combined/${id}`)

const getBunkerCosts = ({ routeId }) =>
  axios.get(`${url}/api/RouteFuelPort/GetAll/${routeId}`)

const postBunkerCost = ({ data }) =>
  axios.post(`${url}/api/RouteFuelPort`, data)

const putBunkerCost = ({ data }) => axios.put(`${url}/api/RouteFuelPort`, data)

const deleteBunkerCost = ({ id }) =>
  axios.delete(`${url}/api/RouteFuelPort/${id}`)

export const updateSortOrder = ({ routeId, oldPosition, newPosition }) =>
  axios.put(
    `${url}/api/Route/UpdateSortOrder/${routeId}/${oldPosition}/${newPosition}`
  )

// Queries
export const useRoutesQuery = ({type}) => {
  const listPreset = useListPreset()
  return useQuery(['routes', type], () => getRoutes({type}), listPreset)
}

export const useRouteQuery = ({ id }) => {
  const listPreset = useListPreset()
  return useQuery(['route', id], () => getRoute({ id }), listPreset)
}

export const useCreateRouteMutation = () => {
  const createPreset = useCreatePreset('routes')
  return useMutation(row => postRoute({ data: row }), createPreset)
}

export const useEditRouteMutation = () => {
  const editPreset = useEditPreset('routes')
  return useMutation(row => putRoute({ data: row }), editPreset)
}

export const usePushRouteMutation = () => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(
    async input => {
      const {
        portConsumptions = {},
        seaConsumptions = {},
        fuelConsumptions = {},
        combinedRoutes = {},
        bunkerCosts = {},
        _delete = {},
        ...data
      } = input
      let routId
      if (data.id) {
        const result = await putRoute({ data })
        routId = result.data.id
      } else {
        const result = await postRoute({ data })
        routId = result.data.id
      }
      const marketType = data.marketType
      return Promise.all([
        ...Object.values(seaConsumptions).map(data =>
          data.id ? putSeaConsumption({ data }) : postSeaConsumption({ data })
        ),
        ...(_delete?.seaConsumptions || []).map(data =>
          deleteSeaConsumption({ id: data.id })
        ),
        ...Object.values(portConsumptions).map(data =>
          data.id ? putPortConsumption({ data }) : postPortConsumption({ data })
        ),
        ...(_delete?.portConsumptions || []).map(data =>
          deletePortConsumption({ id: data.id })
        ),
        ...Object.values(fuelConsumptions).map(data =>
          data.id ? putFuelConsumption({ data }) : postFuelConsumption({ data })
        ),
        ...(_delete?.fuelConsumptions || []).map(data =>
          deleteFuelConsumption({ id: data.id })
        ),
        ...Object.values(combinedRoutes).map(data => {
          return data.id
            ? putCombinedRoute({ data: { ...data, marketType, mainRouteId: routId } })
            : postCombinedRoute({ data: { ...data, marketType, mainRouteId: routId } })
        }),
        ...(_delete?.combinedRoutes || []).map(data =>
          deleteCombinedRoute({ id: data.id })
        ),
        ...Object.values(bunkerCosts).map(data =>
          data.id ? putBunkerCost({ data }) : postBunkerCost({ data })
        ),
        ...(_delete?.bunkerCosts || []).map(data =>
          deleteBunkerCost({ id: data.id })
        )
      ])
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries('routes')
        queryClient.invalidateQueries('futureRoutes')
        const route = data?.data
        if (isNil(route)) return
        queryClient.refetchQueries(['seaConsumptions', route.id])
        queryClient.refetchQueries(['portConsumptions', route.id])
        queryClient.refetchQueries(['fuelConsumptions', route.id])
        queryClient.refetchQueries(['combinedRoutes', route.id])
        queryClient.refetchQueries(['bunkerCosts', route.id])
      },
      onError: (err, input, context) => {
        pushAlert({
          color: 'danger',
          message: 'An error occurred while saving your route.'
        })
      },
      onSuccess: () => {
        pushAlert({
          color: 'success',
          message: 'Your route has been saved successfully.'
        })
      }
    }
  )
}

export const useRemoveRouteMutation = () => {
  const removePreset = useRemovePreset('routes')
  return useMutation(row => deleteRoute({ id: row.id }), removePreset)
}

export const useSeaConsumptionsQuery = ({ routeId }) => {
  const queryKey = ['seaConsumptions', routeId]
  const listPreset = useListPreset(queryKey)
  return {
    ...useQuery(queryKey, () => getSeaConsumptions({ routeId }), listPreset),
    queryKey
  }
}

export const usePortConsumptionsQuery = ({ routeId }) => {
  const queryKey = ['portConsumptions', routeId]
  const listPreset = useListPreset(queryKey)
  return {
    ...useQuery(queryKey, () => getPortConsumptions({ routeId }), listPreset),
    queryKey
  }
}

export const useFuelConsumptionsQuery = ({ routeId }) => {
  const queryKey = ['fuelConsumptions', routeId]
  const listPreset = useListPreset(queryKey)
  return {
    ...useQuery(queryKey, () => getFuelConsumptions({ routeId }), listPreset),
    queryKey
  }
}

export const useCombinedRoutesQuery = ({ routeId }) => {
  const queryKey = ['combinedRoutes', routeId]
  const listPreset = useListPreset(queryKey)
  return {
    ...useQuery(queryKey, () => getCombinedRoutes({ routeId }), listPreset),
    queryKey
  }
}

export const useBunkerCostsQuery = ({ routeId }) => {
  const queryKey = ['bunkerCosts', routeId]
  const listPreset = useListPreset(queryKey)
  return {
    ...useQuery(queryKey, () => getBunkerCosts({ routeId }), listPreset),
    queryKey
  }
}
