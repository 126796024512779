import { createSlice } from '@reduxjs/toolkit'

export const alertsSelector = state => state.alertsSlice

const initialState = []

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    push: (state, action) => {
      state.push({
        createdAt: new Date().getTime(),
        timeout: action.payload.timeout || 10000,
        color: action.payload.color || 'info',
        ...action.payload
      })
    }
  }
})
