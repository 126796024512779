import moment from 'moment'
import { isValid, parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import {isEmpty, isNil} from "ramda";

export const getFormattedDate = () => format(Date.now(), 'dd-MMM-yyyy')

export const isClearDate = date => {
  const m = moment(date)
  return m.day() === 1 && m.month() === 1 && m.year() === 1
}

export const formatInTimeZone = (date, fmt, tz) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

export const validateAndFormat = (iso, f, fallback) => {
  const date = parseISO(iso)
  return isValid(date) && !isClearDate(date)
    ? formatInTimeZone(date, f, 'UTC')
    : fallback
}

export const ISOToDisplay = iso => validateAndFormat(iso, 'dd-MMM-yy', '-')

export const parseInUTC = (date) => {
  let layCanStartDate = parseISO(date)
  return new Date(layCanStartDate.getTime() + layCanStartDate.getTimezoneOffset() * 60000)
}

const convertStringToTime = (input, date) => {
  let test = input.split(':')
  if (test.length === 1) {
    if (test[0].length === 4) {
      test[1] = test[0].substring(2, 4)
      test[0] = test[0].substring(0, 2)
    }
  }
  date.setUTCHours(Number(test[0]))
  date.setUTCMinutes(Number(test[1]))
  return date
}

export const getDateDefaultUTC = () =>
  new Date(Date.UTC(1970, 1, 0, 0, 0, 0, 0))

export const getNowUTC = () => {
  const date = new Date()
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  return new Date(now_utc)
}

const convertStringToDate = input => {
  let date = getDateDefaultUTC()
  let testArray = input.split('/')
  if (testArray.length === 1) {
    testArray = input.split('-')
  }
  if (testArray.length === 2) {
    date.setUTCFullYear(
      new Date().getUTCFullYear(),
      Number(testArray[1]) - 1,
      Number(testArray[0])
    )
  } else if (testArray.length === 3) {
    const year =
      testArray[2].length <= 2
        ? 2000 + Number(testArray[2])
        : Number(testArray[2])
    date.setUTCFullYear(year, Number(testArray[1]) - 1, Number(testArray[0]))
  }
  return date
}

export const editableToISO = editable => {

  if(isNil(editable) || isEmpty(editable)) return null;

  const parsedDate = moment(editable, 'DD-MM-YYYY HH:mm', true)
  if (parsedDate.isValid()) return parsedDate.utc(true).toISOString()
  const testArray = editable.split(' ')
  let date = convertStringToDate(testArray[0])
  if (testArray.length === 2) {
    date = convertStringToTime(testArray[1], date)
  }
  return date.toISOString()
}
