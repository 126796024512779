import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  deleteVesselTrade, getLastUpdateVesselTrade,
  getVesselTrade,
  postVesselTrade,
  putVesselTrade
} from "../queries";
import * as R from "ramda";
import {getLastUpdate} from "../datatime";
import {alertsSlice} from "./alerts";
import {isAfter, parseISO} from "date-fns";


export const fetchVesselTrade = createAsyncThunk(
  'vesselTrade/fetchVesselTrade',
  async () => {
    const { data } = await getVesselTrade({ lastUpdate: null })
    return data
  }
)

export const createVesselTrade = createAsyncThunk(
  'vesselTrade/createVesselTrade',
  async input => {
    const { data } = await postVesselTrade({ data: input })
    return data
  }
)

export const editVesselTrade = createAsyncThunk(
  'vesselTrade/editVesselTrade',
  async (input, { dispatch }) => {
    dispatch(vesselTradeSlice.actions.set(input))
    const { data } = await putVesselTrade({ data: input })
    dispatch(vesselTradeSlice.actions.set(data))
    return data
  }
)

export const removeVesselTrade = createAsyncThunk(
  'vesselTrade/removeVesselTrade',
  async (input, { dispatch }) => {
    dispatch(vesselTradeSlice.actions.delete(input))
    const { data } = await deleteVesselTrade({ id: input.id })
    dispatch(
      alertsSlice.actions.push({
        color: 'success',
        message: 'Your entry has been deleted successfully.'
      })
    )
    return data
  }
)

export const fetchVesselTradeUpdates = createAsyncThunk(
  'vesselTrade/fetchVesselTradeUpdates',
  async (_, { getState }) => {
    const { lastUpdate } = vesselTradeSelector(getState())
    if (!lastUpdate) return null

    const { data: remoteLastUpdate } = await getLastUpdateVesselTrade()
    if (isAfter(parseISO(remoteLastUpdate), parseISO(lastUpdate))) {
      const { data } = await getVesselTrade({ lastUpdate: remoteLastUpdate })
      return data
    }
  }
)


const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null,
  isCreating: false
}
export const vesselTradeSlice = createSlice({
  name: 'vesselTrade',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.id] = action.payload
    },
    delete: (state, action) => {
      delete state.data[action.payload.id]
    }
  },
  extraReducers: {
    [fetchVesselTrade.pending]: state => {
      state.status = 'loading'
    },
    [fetchVesselTrade.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchVesselTrade.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('id'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchVesselTradeUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('id'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    },
    [createVesselTrade.pending]: state => {
      state.isCreating = true
    },
    [createVesselTrade.fulfilled]: (state, action) => {
      state.data[action.payload.id] = {
        ...action.payload,
        _newity: new Date().getTime()
      }
      state.isCreating = false
      state.lastUpdate = getLastUpdate()
    }
  }
})

export const vesselTradeSelector = state => state.vesselTradeSlice
