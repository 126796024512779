import axios from 'axios'
import { url } from './index'
import AxiosStream from "axios-stream";
import {getFormattedDate} from "../../shared/ui/magic-table/dateHelpers";

export const getVesselTrade = () => {
  return axios.get(`${url}/api/VesselTrade`)
}

export const postVesselTrade = ({ data }) => {
  return axios.post(`${url}/api/VesselTrade`, data)
}

export const putVesselTrade = ({ data }) => {
  return axios.put(`${url}/api/VesselTrade/UpdateVesselTrade`, data)
}

export const deleteVesselTrade = ({ id }) => {
  return axios.delete(`${url}/api/VesselTrade/${id}`)
}

export const getLastUpdateVesselTrade = () => {
  return axios.get(`${url}/api/VesselTrade/GetLatestVesselTradeUpdate`)
}

export const downloadVesselTrades = () =>
  AxiosStream.download(`VesselTrades_${getFormattedDate()}`, 'xlsx', {
    url: `${url}/api/VesselTrade/ExportToExcel`
  })
