import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {isAfter, parseISO } from 'date-fns'
import * as R from 'ramda'
import {
  deleteTimeCharter,
  getTimeCharters,
  getTimeCharterUpdates,
  postTimeCharter,
  putTimeCharter
} from '../queries'
import { alertsSlice } from './alerts'
import {getLastUpdate} from "../datatime";

export const timeChartersSelector = state => state.timeChartersSlice

export const fetchTimeCharters = createAsyncThunk(
  'timeCharters/fetchTimeCharters',
  async () => {
    const { data } = await getTimeCharters({ lastUpdate: null })
    return data
  }
)

export const fetchTimeCharterUpdates = createAsyncThunk(
  'timeCharters/fetchTimeCharterUpdates',
  async (_, { getState }) => {
    const { lastUpdate } = timeChartersSelector(getState())
    if (!lastUpdate) return null

    const { data: remoteLastUpdate } = await getTimeCharterUpdates()
    if (isAfter(parseISO(remoteLastUpdate), parseISO(lastUpdate))) {
      const { data } = await getTimeCharters({ lastUpdate: remoteLastUpdate })
      return data
    }
  }
)

export const createTimeCharter = createAsyncThunk(
  'timeCharters/createTimeCharter',
  async input => {
    const { data } = await postTimeCharter({ data: input })
    return data
  }
)

export const editTimeCharter = createAsyncThunk(
  'timeCharters/editTimeCharter',
  async (input, { dispatch }) => {
    dispatch(timeChartersSlice.actions.set(input))
    const { data } = await putTimeCharter({ data: input })
    return data
  }
)

export const removeTimeCharter = createAsyncThunk(
  'timeCharters/removeTimeCharter',
  async (input, { dispatch }) => {
    dispatch(timeChartersSlice.actions.delete(input))
    const { data } = await deleteTimeCharter({ id: input.id })
    dispatch(
      alertsSlice.actions.push({
        color: 'success',
        message: 'Your time charter has been deleted successfully.'
      })
    )
    return data
  }
)

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null,
  isCreating: false
}

export const timeChartersSlice = createSlice({
  name: 'timeCharters',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.id] = action.payload
    },
    delete: (state, action) => {
      delete state.data[action.payload.id]
    }
  },
  extraReducers: {
    [fetchTimeCharters.pending]: state => {
      state.status = 'loading'
    },
    [fetchTimeCharters.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchTimeCharters.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('id'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchTimeCharterUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('id'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    },
    [createTimeCharter.pending]: state => {
      state.isCreating = true
    },
    [createTimeCharter.fulfilled]: (state, action) => {
      state.data[action.payload.id] = {
        ...action.payload,
        _newity: new Date().getTime()
      }
      state.isCreating = false
      state.lastUpdate = getLastUpdate()
    }
  }
})
