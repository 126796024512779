import axios from 'axios'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { url, useListPreset, useReadPreset, useRemovePreset } from '.'
import { AlertContext } from '../contexts'
import * as R from 'ramda'

// Endpoints
const getRouteAssessments = ({ date, type }) =>
  axios.get(`${url}/api/RouteAssessment/GetAll/${type}`, { params: { date } })

export const getRouteAssessment = ({ id }) =>
  axios.get(`${url}/api/RouteAssessment/${id}`)

const putRouteAssessment = ({ data }) =>
  axios.put(`${url}/api/RouteAssessment`, data)

const deleteRouteAssessment = ({ id }) =>
  axios.delete(`${url}/api/RouteAssessment/${id}`)

// Queries
export const useRouteAssessmentsQuery = ({ date, type }) => {
  const queryKey = ['routeAssessments', type, date]
  const listPreset = useListPreset()
  return {
    ...useQuery(queryKey, () => getRouteAssessments({ date, type }), {
      ...listPreset,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }),
    queryKey
  }
}

export const useRouteAssessmentQuery = ({ id, marketAssessmentQueryKey }) => {
  const queryClient = useQueryClient()
  const readPreset = useReadPreset()
  return useQuery(['routeAssessment', id], () => getRouteAssessment({ id }), {
    ...readPreset,
    onSuccess: data => {
      queryClient.setQueryData(marketAssessmentQueryKey, old => {
        let routeAssessment = data.routeAssessment
        old.data.routeAssessments = old.data.routeAssessments.map(a => {
          return a.id === routeAssessment.id ? routeAssessment : a
        })
        return { ...old }
      })
    }
  })
}

export const usePushRouteAssessmentMutation = queryKey => {
  const queryClient = useQueryClient()
  const { pushAlert } = useContext(AlertContext)

  return useMutation(
    data => {
      return Array.isArray(data)
        ? Promise.all(
            R.map(value => putRouteAssessment({ data: value }), data)
          )
        : putRouteAssessment({ data: data })
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(queryKey, old => {
          const assessments = Array.isArray(data) ? R.flatten(R.map(d => d.data, data)) : data.data
          const ids = R.map(d => d.id, assessments)
          old.data.routeAssessments = [
            ...old.data.routeAssessments.filter(a => !ids.includes(a.id)),
            ...assessments
          ]
          return { ...old }
        })
      },
      onError: (err, input, context) => {
        if (err.response.status === 422) {
          pushAlert({
            color: 'danger',
            message: 'Set all bunker prices before calculating TCE.'
          })
        } else {
          pushAlert({
            color: 'danger',
            message: 'An error occurred while saving your assessment.'
          })
        }
      }
    }
  )
}

export const useRemoveRouteAssessmentMutation = () => {
  const removePreset = useRemovePreset('routeAssessments')
  return useMutation(row => deleteRouteAssessment({ id: row.id }), removePreset)
}
