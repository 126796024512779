import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {isAfter, parseISO } from 'date-fns'
import * as R from 'ramda'
import {getVessels, getVesselUpdates, updateVessels} from '../queries'
import {getLastUpdate} from "../datatime";

export const fleetSelector = state => state.fleetSlice

export const fetchVessels = createAsyncThunk('fleet/fetchVessels', async () => {
  const { data } = await getVessels({ lastUpdate: null })
  return data
})

export const fetchVesselUpdates = createAsyncThunk(
  'fleet/fetchVesselUpdates',
  async (_, { getState }) => {
    const { lastUpdate } = fleetSelector(getState())
    if (!lastUpdate) return null

    const { data: remoteLastUpdate } = await getVesselUpdates()
    if (isAfter(parseISO(remoteLastUpdate), parseISO(lastUpdate))) {
      const { data } = await getVessels({ lastUpdate: remoteLastUpdate })
      return data
    }
  }
)

export const editVessel = createAsyncThunk(
  'fleet/editVessel',
  async (input, { dispatch }) => {
    dispatch(fleetSlice.actions.set(input))
    const { data } = await updateVessels( { data: input })
    return data
  }
)

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null
}

export const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.id] = action.payload
    }
  },
  extraReducers: {
    [fetchVessels.pending]: state => {
      state.status = 'loading'
    },
    [fetchVessels.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchVessels.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('id'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchVesselUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('id'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    }
  }
})
