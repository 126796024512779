import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isAfter, parseISO } from 'date-fns'
import * as R from 'ramda'
import { getLastUpdate } from '../datatime'
import {
  deleteCargo,
  deleteCargoComment,
  getFixtureUpdates,
  getFixtures,
  putCargo,
  putCargoComment,
  putCargoConfirmation,
  sendCargo
} from '../queries'
import { alertsSlice } from './alerts'

export const fixturesSelector = state => state.fixturesSlice

export const fetchFixtures = createAsyncThunk(
  'fixtures/fetchFixtures',
  async ({ getAll }) => {
    const { data } = await getFixtures({ lastUpdate: null, getAll })
    return data
  }
)

export const fetchFixtureUpdates = createAsyncThunk(
  'fixtures/fetchFixtureUpdates',
  async (_, { getState }) => {
    const { lastUpdate } = fixturesSelector(getState())
    if (!lastUpdate) return null

    const { data: remoteLastUpdate } = await getFixtureUpdates()
    if (isAfter(parseISO(remoteLastUpdate), parseISO(lastUpdate))) {
      const { data } = await getFixtures({ lastUpdate: remoteLastUpdate })
      return data
    }
  }
)

export const editFixture = createAsyncThunk(
  'fixtures/editFixture',
  async (input, { dispatch }) => {
    dispatch(fixturesSlice.actions.set(input))
    const { data } = await putCargo({ data: input })
    return data
  }
)

export const removeFixture = createAsyncThunk(
  'fixtures/removeFixture',
  async (input, { dispatch }) => {
    dispatch(fixturesSlice.actions.delete(input))
    const { data } = await deleteCargo({ id: input.id })
    dispatch(
      alertsSlice.actions.push({
        color: 'success',
        message: 'Your fixture has been deleted successfully.'
      })
    )
    return data
  }
)

export const sendFixture = createAsyncThunk(
  'fixtures/sendFixture',
  async ({ row, onlySave = false }) => {
    const { data } = await sendCargo({ data: row, onlySave })
    return data
  }
)

export const confirmFixture = createAsyncThunk(
  'fixtures/confirmFixture',
  async ({ cargo, confirmations, comments, _delete }, { dispatch }) => {
    try {
      await Promise.all([
        ...Object.values(comments).map(data => putCargoComment({ data })),
        ...(_delete?.comments || []).map(data =>
          deleteCargoComment({ id: data.id })
        )
      ])
      if (cargo.status !== 'FIXED') {
        const { data } = await putCargoConfirmation({
          cargoId: cargo.id,
          data: confirmations
        })
        return data
      }
      return {}
    } catch (err) {
      dispatch(
        alertsSlice.actions.push({
          color: 'danger',
          message: 'An error occurred while confirming this fixture.'
        })
      )
      throw err
    }
  }
)

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  lastUpdate: null
}

export const fixturesSlice = createSlice({
  name: 'fixtures',
  initialState,
  reducers: {
    set: (state, action) => {
      state.data[action.payload.id] = action.payload
    },
    delete: (state, action) => {
      delete state.data[action.payload.id]
    }
  },
  extraReducers: {
    [fetchFixtures.pending]: state => {
      state.status = 'loading'
    },
    [fetchFixtures.rejected]: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    [fetchFixtures.fulfilled]: (state, action) => {
      state.status = 'success'
      state.data = R.indexBy(R.prop('id'), action.payload)
      state.lastUpdate = getLastUpdate()
    },
    [fetchFixtureUpdates.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data = {
          ...state.data,
          ...R.indexBy(R.prop('id'), action.payload)
        }
        state.lastUpdate = getLastUpdate()
      }
    },
    [sendFixture.fulfilled]: (state, action) => {
      state.data[action.payload.id] = action.payload
      state.lastUpdate = getLastUpdate()
    },
    [confirmFixture.fulfilled]: (state, action) => {
      const allConfirmed =
        action.payload.length &&
        action.payload.every(({ confirmed }) => confirmed)
      const oldData = state.data[action.payload[0].cargoId]

      if (oldData.chartererConfirmed !== allConfirmed) {
        state.data[action.payload[0].cargoId] = {
          ...oldData,
          chartererConfirmed: allConfirmed
        }
        state.lastUpdate = getLastUpdate()
      }
    }
  }
})
